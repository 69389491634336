const $ = require('jquery');
window.$ = $;
window.jQuery = $;

const jsZip = require('../../node_modules/admin-lte/plugins/jszip/jszip.min.js');
window.JSZip = jsZip;

const adminLTE = require('admin-lte');

require('../../node_modules/jquery-ui-dist/jquery-ui.min.js');

require('../images/sidebar/logo-white.png');
require('../images/logo.png');
require('../images/pdf/pdf-logo.png');

require('../../node_modules/admin-lte/dist/css/adminlte.css');
require('../../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.min.js');

require('../../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js');
require('../../node_modules/admin-lte/plugins/pdfmake/pdfmake.min.js');
require('../../node_modules/admin-lte/plugins/pdfmake/vfs_fonts.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.flash.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.html5.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.print.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.colVis.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js');
require('../../node_modules/admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css');
require('../../node_modules/datatables.net/');

require('../../node_modules/admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css');
require('../../node_modules/admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js');
require('../../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js');
require('../../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js');

require('../../node_modules/overlayscrollbars/js/jquery.overlayScrollbars.min.js')
require('../../node_modules/overlayscrollbars/css/OverlayScrollbars.min.css')
require('../../node_modules/micromodal/dist/micromodal.min.js');
require('../../node_modules/tinymce/tinymce.min.js');
require('../../node_modules/tinymce/themes/silver/theme.min.js');
require('../../node_modules/tinymce/skins/ui/oxide/skin.min.css');
require('../../node_modules/tinymce/skins/content/default/content.min.css');
require('../../node_modules/tinymce/skins/ui/oxide/content.min.css');
require('../../node_modules/tinymce/plugins/autosave/plugin.min.js');
require('../../node_modules/tinymce/plugins/visualblocks/plugin.min.js');
require('../../node_modules/tinymce/plugins/autoresize/plugin.min.js');
require('../../node_modules/tinymce/plugins/lists/plugin.min.js');
require('../../node_modules/tinymce/plugins/table/plugin.min.js');
require('../../node_modules/tinymce/plugins/code/plugin.min.js');
require('../../node_modules/tinymce/plugins/image/plugin.min.js');
require('../../node_modules/tinymce/plugins/imagetools/plugin.min.js');
require('../../node_modules/tinymce/plugins/fullscreen/plugin.min.js');

require('../../node_modules/lightbox2/dist/js/lightbox.min.js');
require('../../node_modules/lightbox2/dist/css/lightbox.min.css');
require('../../node_modules/lightbox2/dist/images/close.png');
require('../../node_modules/lightbox2/dist/images/loading.gif');
require('../../node_modules/lightbox2/dist/images/next.png');
require('../../node_modules/lightbox2/dist/images/prev.png');

require('../../node_modules/waitme/waitMe.min.css');
require('../../node_modules/waitme/waitMe.min.js');

require('../../node_modules/tinymce/icons/default/icons.min.js');

require('../../node_modules/jstree/dist/jstree.min.js');
require('../../node_modules/jstree/src/jstree.types.js');
require('../../node_modules/jstree/dist/themes/default/style.min.css');
require('../../node_modules/jstree/dist/themes/default/32px.png');
require('../../node_modules/jstree/dist/themes/default/40px.png');
require('../../node_modules/jstree/dist/themes/default/throbber.gif');

require('../../node_modules/admin-lte/plugins/select2/js/select2.full.min');
require('../../node_modules/admin-lte/plugins/select2/css/select2.min.css');
require('../../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js');
require('../../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css');
require('../../node_modules/jqdoublescroll/jquery.doubleScroll');
require('../../node_modules/jquery.typewatch/jquery.typewatch');

require('../../node_modules/chart.js');
require('../../node_modules/chartjs-color/index.js');
require('../../node_modules/chartjs-color-string/color-string.js');

require('../scss/app.scss');

require('./main');