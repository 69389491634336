export default class Notification {

    /**
     * @param message
     * @param close
     */
    setSuccessAlert(message, close = false) {
        $(document).Toasts('create', {
            title: message,
            class: 'toast-success',
            close: false,
            autohide: close ? false : true,
            delay: 5000,
            close: close,
            icon: 'fas fa-check'
        });
    }

    /**
     * @param message
     * @param close
     */
    setErrorAlert(message, close = false) {
        $(document).Toasts('create', {
            title: message,
            class: 'toast-error',
            close: false,
            autohide: close ? false : true,
            delay: 5000,
            close: close,
            icon: 'fas fa-exclamation-triangle'
        });
    }

    /**
     * @param message
     * @param close
     */
    setInfoAlert(message, close = false) {
        $(document).Toasts('create', {
            title: message,
            class: 'toast-info',
            close: false,
            autohide: close ? false : true,
            delay: 5000,
            close: close,
            icon: 'fas fa-info'
        });
    }

    /**
     * @param message
     * @param close
     */
    setWarningAlert(message, close = false) {
        $(document).Toasts('create', {
            title: message,
            class: 'toast-warning',
            close: false,
            autohide: close ? false : true,
            delay: 5000,
            close: close,
            icon: 'fas fa-exclamation-triangle'
        });
    }
}