import Table from "./Table.js";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import Notification from './Notification.js';
import Loader from "./Loader";

const routes = require('../../../public/js/fos_js_routes.json');


Routing.setRoutingData(routes);

export default class Shelves {

    /**
     * @param $shelfList
     * @returns {jQuery}
     */
    createListTable($shelfList) {
        let shelf = +$shelfList.attr('data-shelf');

        const TableObject = new Table();
        return TableObject.createTable($shelfList, {
            'ajax': Routing.generate('warehouse_shelf_info', {
                'shelf': shelf
            }),
            'columnDefs': [
                {'targets': 0, 'orderable': false},
                {'name': 'p.serialNumber', 'targets': 2},
                {'name': 's.skuShortName', 'targets': 3},
                {'name': 'sku', 'targets': 4},
                {'name': 's.iaiCode', 'targets': 5},
                {'name': 'diagnosticCodes', 'targets': 6},
                {'name': 'g.name', 'targets': 7},
                {'name': 'sb.name', 'targets': 9},
                {'name': 'pr.name', 'targets': 10},
                {'name': 'pt.name', 'targets': 11},
                {'name': 'mn.name', 'targets': 12},
                {'name': 'cn.name', 'targets': 13},
                {'name': 'ctk.name', 'targets': 14},
                {'name': 'r.name', 'targets': 15},
                {'name': 'hd.name', 'targets': 16},
                {'name': 'ss.name', 'targets': 17},
                {'name': 'sr.name', 'targets': 18},
                {'name': 'gpu.name', 'targets': 19},
                {'name': 'sg.name', 'targets': 20},
                {'name': 'l.name', 'targets': 21},
                {'name': 'p.remark', 'targets': 22},
                {'name': 'location', 'targets': 23},
            ],
            'columns': [
                {'className': 'action', 'targets': 0},
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
        });
    }

    /**
     * @returns {*}
     * @param $table
     */
    addProductsFromBasketToShelf(basketNumber, $table) {
        let shelf = +$table.attr('data-shelf');
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($table);
        $('.rnew-modal-close').trigger('click');
        let url = Routing.generate(
            'warehouse_shelf_basket_add',
            {shelf: shelf, basket: basketNumber}
        );

        return $.when($.ajax({
            type: 'POST',
            url: url,
            contentType: false,
            processData: false,
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                if ($table) {
                    $table.DataTable().ajax.reload()
                }

                LoaderObject.removeLoader(loaderSpinner);
                response.forEach(showNotification);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);
                let errMessage = '';
                if (xhr.responseJSON) {
                    errMessage = xhr.responseJSON.error;
                } else {
                    errMessage = error;
                }
                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(errMessage);
            }
        }))
    }

    /**
     * @returns {*}
     * @param $table
     */
    addProductsFromShelfToBasket(basketNumber, $table) {
        let shelf = +$table.attr('data-shelf');
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($table);
        $('.rnew-modal-close').trigger('click');
        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate(
                'warehouse_shelf_add_to_basket',
                {shelf: shelf, basket: basketNumber}
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                LoaderObject.removeLoader(loaderSpinner);
                response.forEach(showNotification);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    currentBasketCount(anchor, basketNumber) {
        return $.ajax({
            type: 'POST',
            url: Routing.generate(
                'basket_get_current_count',
                { basket: basketNumber}
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                $(anchor).data('basket-count',  response.currentCount);
            },
            error: function (xhr, status, error) {
            }
        })
    }

    currentShelfCount(anchor, shelf) {
        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate(
                'warehouse_shelf_get_current_count',
                { shelf: shelf}
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                $(anchor).data('shelf-count', response.currentCount);
            },
            error: function (xhr, status, error) {
                return null;
            }
        }))
    }
    /**
     *
     */
    handleEvent() {
        let $shelfList = $('.shelf-content-table');

        if ($shelfList.length > 0) {
            this.createListTable($shelfList);
        }

        $(".choose-basket-shelves .choose-basket-select").on('change', (event) => {
            event.preventDefault();
            let basket = $(".choose-basket-select option:selected");
        
            $("a[data-basket-name]").each(function () {
                let basketName = basket.text();

                $(this).data('basket-name', basketName);
            });
            let basketNumber = basket.val();

            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($(event.target.parentElement.parentElement.parentElement.parentElement));

            $.when(this.currentBasketCount($("a[data-basket-count]"), basketNumber))
            .then(this.currentShelfCount($("a[data-shelf-count]"), $shelfList.attr('data-shelf')))
            .then(() => {LoaderObject.removeLoader(loaderSpinner);});

        });
        $(".choose-basket-select").change();

        let $basketAddButton = $('.shelf-basket-add-btn');

        $basketAddButton.on('click', (event) => {
            event.preventDefault();
            let basketNumber = $(".choose-basket-select option:selected").val();

            this.addProductsFromBasketToShelf(basketNumber, $shelfList);
        });

        let $shelfAddButton = $('.basket-shelf-add-btn');

        $shelfAddButton.on('click', (event) => {
            event.preventDefault();
            let basketNumber = $(".choose-basket-select option:selected").val();

            this.addProductsFromShelfToBasket(basketNumber, $shelfList);
        });
    }

    init() {
        this.handleEvent();
    }
}
