export default class Table {

    /**
     * @param data
     * @returns {string}
     */
    formatDataForExporting(data) {
        if (data) {
            data = data.toString().trim();
            // data = data.replace(/<span.*<\/span>/g, '');
            data = data.replace(/<\/li>/g, '; ');
            data = data.replace( /<br\s*\/?>/ig, "\n" )
            data = data.replace(/(<([^>]+)>)/gi, '');
            data = data.replace('------', '');
            data = data.replace(/Więcej \(\d+\)/g,'');
            data = data.replace(/Mniej/g,'');
        }

        return data;
    }

    /**
     * @param $dataTable
     * @param data
     * @returns {jQuery}
     */
    createTable($dataTable, data) {
        let that = this;
        let config = $.extend({
            language: {
                'url': "//cdn.datatables.net/plug-ins/1.10.21/i18n/Polish.json"
            },
            dom: 'lBip<"table-responsive"rt>p',
            pagingType: 'numbers',
            responsive: false,
            orderCellsTop: true,
            lengthMenu: [[10, 100, 1000, 10000], [10, 100, 1000, 10000]],
            pageLength: 100,
            fixedHeader: false,
            createdRow: function (row, data, dataIndex, cells) {
                if (typeof data['rowClass'] !== "undefined") {
                    if (data['rowClass']) {
                        $(row).addClass(data['rowClass']);
                    }
                    delete data['rowClass'];
                }
            },
            buttons: [{
                extend: 'excelHtml5',
                className: 'btn btn-primary rnew-green-button rnew-primary-button',
                titleAttr: 'Excel',
                title: '',
                exportOptions: {
                    
                    columns: ':visible:not(.action):not(.checkbox-row)',
                    format: {
                        body: (data, row, column, node) => {
                            if (typeof data === 'string' && data.includes('<input')) {
                                data = parseFloat($(data).val().replace(/,/, '.')).toFixed(2);
                            }
                            return that.formatDataForExporting(data);
                        }
                    }
                },
                text: '<i class="fas fa-file-excel"></i> Eksportuj do excel'
            }, {
                extend: 'csv',
                className: 'btn btn-primary rnew-green-button rnew-primary-button',
                titleAttr: 'CSV',
                title: '',
                exportOptions: {
                    columns: ':visible:not(.action)',
                    format: {
                        body: (data, row, column, node) => {
                            if (typeof data === 'string' && data.includes('<input')) {
                                data = parseFloat($(data).val().replace(/,/, '.')).toFixed(2);
                            }
                            return that.formatDataForExporting(data);
                        }
                    }
                },
                text: '<i class="fas fa-file-csv"></i> Eksportuj do CSV'
            }],
            initComplete: function () {
                that.addSearchColumns($dataTable, this);
                that.makeResponsive($dataTable);
                //that.addSelection($dataTable);
            }
        }, data);

        let ajaxEnabled = typeof $dataTable.attr('data-ajax-process') !== 'undefined';

        if (ajaxEnabled) {
            config["processing"] = true;
            config["serverSide"] = true;
        }

        $.fn.dataTableExt.oStdClasses['sWrapper'] = 'rnew-table-wrapper dataTables_wrapper dt-bootstrap4';

        return $dataTable.DataTable(config);
    }

    addSearchColumns($dataTable, table) {
        let position = 1;
        let $thead = $dataTable.find('thead');

        let selectData = $dataTable.attr('data-select-data');

        if (typeof selectData !== 'undefined' && selectData.length !== 0) {
            selectData = JSON.parse(selectData);
        }

        $thead.append('<tr></tr>');
        $thead.find('tr:first th').each(function () {
            let searchEnabled = typeof $(this).attr('data-search') !== 'undefined';
            let selectEnabled = typeof $(this).attr('data-select') !== 'undefined';

            let html = '<td class="sorting_disabled">';

            if (searchEnabled) {
                html += '<input id="input' + position + '" list="' + position + '" type="text" class="form-control">';
            }

            if (selectEnabled) {
                html += '<datalist id="' + position + '">';
                let selectSection = $(this).attr('data-select');

                html += '<option value="">------</option>';

                if (selectSection.length > 0) {
                    for (let i in selectData[selectSection]) {
                        html += '<option value="' + selectData[selectSection][i].name + '">' + selectData[selectSection][i].name + '</option>';
                    }
                }

                html += '</datalist>';
            }

            html += '</td>';

            $thead.find('tr:last')
                .append(html);

            position++;
        });

        position = 1;

        // callback: The callback function
        // wait: The number of milliseconds to wait after the the last key press before firing the callback
        // highlight: Highlights the element when it receives focus
        // allowSubmit: Allows a non-multiline element to be submitted (enter key) regardless of captureLength
        // captureLength: Minimum # of characters necessary to fire the callback

        table.api().columns().every(function () {
            let column = this;
            $('#input' + position).typeWatch({
                callback: function () {
                    if (column.search() !== this.value) {
                        column.search(this.value, true, false, true).draw();
                    }
                },
                wait: 1000,
                highlight: false,
                allowSubmit: true,
                captureLength: 1
            });
            position++;
        });

        $('.dataTables_filter input').off().typeWatch({
            callback: function () {
                if ($dataTable.DataTable().search() !== this.value) {
                    $dataTable.DataTable().search(this.value, true, false, true).draw();
                }
            },
            wait: 1000,
            highlight: false,
            allowSubmit: true,
            captureLength: 2
        });
    }

    /**
     *
     */
    makeResponsive($table) {
        $table.each(function () {
            $(this).doubleScroll({resetOnWindowResize: true});
        });
    }

    // /**
    //  * @param $table
    //  */
    // addSelection($table)
    // {
    //     $table.on('click', 'tr:not(tr:eq(1)):not(tr:eq(0)):not(.no-selection)', function (event) {
    //         if ($(this).hasClass('selected') ) {
    //             $(this).removeClass('selected');
    //         }
    //         else {
    //             $('tr.selected').removeClass('selected');
    //             $(this).addClass('selected');
    //         }
    //     } );
    //
    //     $table.on( 'click', 'td a:not([data-modal])', function (event) {
    //         event.stopPropagation();
    //     });
    // }
}
