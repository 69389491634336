import Table from "./Table";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Loader from "./Loader";
import Notification from "./Notification";
import Modal from "./Modal";

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);
 
export default class Sku {

    /**
     * @returns {jQuery}
     * @param $list
     */
    createListTable($list) {
        const TableObject = new Table();
        return TableObject.createTable($list, {
            'ajax': Routing.generate('sku_list'),
            'columnDefs': [
                { 'targets': 0, 'sortable': false, 'searchable': false },
                { 'name': 's.id', 'targets': 2 },
                { 'name': 's.skuShortName', 'targets': 3 },
                { 'name': 'sku', 'targets': 4 },
                { 'name': 's.iaiCode', 'targets': 5 },
                { 'name': 'pt.name', 'targets': 6 },
                { 'name': 'mt.name', 'targets': 7 },
                { 'name': 'sd.name', 'targets': 8 },
                { 'name': 'n.name', 'targets': 9 },
                { 'name': 'ct.name', 'targets': 10 },
                { 'name': 'cn.name', 'targets': 11 },
                { 'name': 'r.name', 'targets': 12 },
                { 'name': 'hd.name', 'targets': 13 },
                { 'name': 'ss.name', 'targets': 14 },
                { 'name': 'sr.name', 'targets': 15 },
                { 'name': 'l.name', 'targets': 16 },
                { 'name': 'g.name', 'targets': 17 },
            ],
        });
    }

    /**
     * @returns {jQuery}
     * @param $list
     */
    createHulksSoldListTable($list) {
        let that = this;
        const TableObject = new Table();

        const from = $list.data('from');
        const to = $list.data('to');

        let route = Routing.generate('sku_hulks_list_sold');

        if (from.length > 0 && to.length > 0) {
            route = Routing.generate('sku_hulks_list_sold_date', {
                'from' : from,
                'to': to,
            });
        }

        $('.controls input[name="datefilter"].report-date-range')
            .on('apply.daterangepicker', function(event) {
                const value = $(event.currentTarget).val();
                const splitValue = value.split(' - ');

                const from = splitValue[0].trim();
                const to = splitValue[1].trim();

                location.href = Routing.generate('sku_hulks_sold_date', {
                    'from': from,
                    'to': to,
                });
        });

        return TableObject.createTable($list, {
            'ajax': route,
            'columnDefs': [
                {'targets': 0, 'sortable': false, 'searchable': false},
                {'name': 'sk.skuShortName', 'targets': 1},
                {'name': 's.id', 'targets': 4},
                {'name': 'p.orderSource', 'targets': 3},
            ],
            initComplete: function () {
                TableObject.addSearchColumns($list, this);
                TableObject.makeResponsive($list);

            },
            drawCallback: function (settings) {
                that.initRowFormsEventListeners();
                that.initShowMore();
            }
        });
    }

    /**
     * @returns {jQuery}
     * @param $list
     */
    createKadlubekListTable($list) {
        let that = this;
        const TableObject = new Table();
        // TableObject.ajax.reload( function ( ) {
        //     that.initRowFormsEventListeners();
        //     that.initShowMore();
        // } );
        let route = Routing.generate('sku_kadlubek_list');
        if ($list.is("[data-stock-only]")) {
            route = Routing.generate('sku_kadlubek_list_stock_only');
        }
        // tbl.on('xhr.dt', function ( e, settings, json, xhr ) {
        //     that.initRowFormsEventListeners();
        //     that.initShowMore();
        // } )

        return TableObject.createTable($list, {
            'ajax': route,
            'columnDefs': [
                {'targets': 0, 'sortable': false, 'searchable': false},
                {'name': 's.id', 'targets': 2},
                {'name': 's.skuShortName', 'targets': 3},
            ],
            initComplete: function () {
                TableObject.addSearchColumns($list, this);
                TableObject.makeResponsive($list);

            },
            drawCallback: function (settings) {
                that.initRowFormsEventListeners();
                that.initShowMore();
            }
        });
    }

    /**
     * @param productType
     * @returns {Sku}
     */
    showProductTypeSelects(productType) {
        let productTypeFields = $('[data-product-type]');

        productTypeFields.each(function () {
            let fieldProductType = $(this).attr('data-product-type');

            let fieldProductTypeArray = fieldProductType.split(',');

            if ($.inArray(productType, fieldProductTypeArray) !== -1) {
                $(this).show();
            } else {
                $(this).find('option:eq(0)').attr('selected', 'selected');
                $(this).hide();
            }
        });

        return this;
    }

    initShowMore() {
        $('a.show-more').each(function () {
            let initialText = $(this).html();

            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowForm = $(event.target.parentElement);
                $rowForm.find('.more-item').each(function () {
                    $(this).toggleClass('hidden');
                });

                $rowForm.toggleClass('expanded');
                if ($rowForm.hasClass('expanded')) {
                    $(this).html('Mniej');
                } else {
                    $(this).html(initialText);
                }
            });
        });
    }


    initRetailPriceAutoCheckbox() {
        $('a.show-more').each(function () {
            let initialText = $(this).html();

            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowForm = $(event.target.parentElement);
                $rowForm.find(".hidden").each(function () {
                    $(this).toggle;
                    $(this).toggleClass('--showed');
                });
                $rowForm.toggleClass('--showed');
                if ($rowForm.hasClass('--showed')) {
                    $(this).html('Mniej');
                } else {
                    $(this).html(initialText);
                }
            });
        });
    }
    /**
     * @returns {*}
     * @param $table
     */
    setPriceSourceRow(checkedSku, $rowForm) {
        let parentModel = $rowForm.attr('data-parent-model');
        let parentCpu = $rowForm.attr('data-parent-cpu') !== "" ? $rowForm.attr('data-parent-cpu') : null;
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($rowForm);

        $.ajax({
            type: 'POST',
            url: Routing.generate('sku_set_price_source'),
            // contentType: "application/json",
            // processData: false,
            // dataType: 'json',
            data: { 'checkedSku': checkedSku, 'parentModel': parentModel, 'parentCpu': parentCpu },
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error, true);
                    }
                }

                let name = $rowForm.find("input").first().attr('name');

                $('input[type="radio"][name="' + name + '"]').each(function () {
                    if ($(this).val() === checkedSku) {
                        $(this).parent().addClass("price-source");
                    } else {
                        $(this).parent().removeClass("price-source");
                    }
                });

                LoaderObject.removeLoader(loaderSpinner);
                showNotification(response);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(error, true);
            }
        });


    }

    initRowFormsEventListeners() {
        let that = this;
        $(".submit-price-source-row-form").each(function () {
            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowForm = $(event.target.parentElement);
                let checkedSku = $('input:checked', $rowForm).val();
                if (checkedSku) {
                    that.setPriceSourceRow(checkedSku, $rowForm);
                }
            });
        });
    }
    /** 
     * @returns {Sku}
     * @param model
     */
    ajaxModelProductType(model) {
        $.when(
            $.ajax({
                url: Routing.generate('sku_model_product_type', { model: model }, false),
                type: 'GET',
                dataType: 'json',
            })
        ).then((result) => {
            this.showProductTypeSelects(result.productType);
        });

        return this;
    }

    /**
     * @param caseTypes
     */
    showCaseTypes(caseTypes) {
        let $caseTypesSelect = $('.case-type-select');
        let caseTypesValue = $caseTypesSelect.val();

        if (caseTypes.length > 0) {
            let html = '<option value="">------</option>';

            for (let ct in caseTypes) {
                html += '<option value="' + caseTypes[ct].id + '">' + caseTypes[ct].name + '</option>';
            }

            $caseTypesSelect.html(html).val(caseTypesValue).closest('.case-types').show();
        } else {
            $caseTypesSelect.html('').closest('.case-types').hide();
        }
    }

    /**
     * @returns {Sku}
     * @param model
     */
    ajaxParentModelCaseTypes(model) {
        $.when(
            $.ajax({
                url: Routing.generate('sku_parent_model_case_types', { model: model }, false),
                type: 'GET',
                dataType: 'json',
            })
        ).then((result) => {
            this.showCaseTypes(result);
        });

        return this;
    }

    print_r(obj, t) {

        // define tab spacing
        var tab = t || '';

        // check if it's array
        var isArr = Object.prototype.toString.call(obj) === '[object Array]' ? true : false;

        // use {} for object, [] for array
        var str = isArr ? ('Array\n' + tab + '[\n') : ('Object\n' + tab + '{\n');

        // walk through it's properties
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                var val1 = obj[prop];
                var val2 = '';
                var type = Object.prototype.toString.call(val1);
                switch (type) {

                    // recursive if object/array
                    case '[object Array]':
                    case '[object Object]':
                        val2 = this.print_r(val1, (tab + '\t'));
                        break;

                    case '[object String]':
                        val2 = '\'' + val1 + '\'';
                        break;

                    default:
                        val2 = val1;
                }
                str += tab + '\t' + prop + ' => ' + val2 + ',\n';
            }
        }

        // remove extra comma for last property
        str = str.substring(0, str.length - 2) + '\n' + tab;

        return isArr ? (str + ']') : (str + '}');
    }

    initModalEvents() {
        let that = this;
        $('#sku_prices_form_modal').submit(function (e) {
            e.preventDefault();

            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($(e.target));

            $.ajax({
                type: 'POST',
                url: Routing.generate('sku_price_sources_modal_submit'),
                contentType: false,
                processData: false,
                data: new FormData(e.target),
                success: function (response) {
                    // window.location.href = Routing.generate('sku_prices_output', {
                    // })
                    response = JSON.parse(response);
                    let ret = that.print_r(response.results);
                    // let modalobj = new Modal();
                    // modalobj.createCustomModal('', `<pre>` + ret + `</pre>`, '');
        
                    document.querySelector('#custom-content').innerHTML = `<pre>` + ret + `</pre>`;
                    LoaderObject.removeLoader(loaderSpinner);
                    // return;
                    function showNotification(element) {
                        const NotificationObject = new Notification();
                        if (element.success) {
                            NotificationObject.setSuccessAlert(element.success, true);
                        } else if (element.info) {
                            NotificationObject.setInfoAlert(element.info, true);
                        } else if (element.error) {
                            NotificationObject.setErrorAlert(element.error, true);
                        }
                    }
                    response.messages.forEach(showNotification);
                    
                },
                error: function (xhr, status, error) {
                    LoaderObject.removeLoader(loaderSpinner);

                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(error, true);
                }
            });
            return
        });
    }
    /**
     *
     */
    handleEvent() {
        let that = this;
        
        // let $modelSelect = $('.model-type');

        // if ($modelSelect.length > 0) {
        //     $modelSelect.on('change', (event) => {
        //         let model = $(event.currentTarget).val();

        //         this.ajaxModelProductType(model);
        //         this.ajaxParentModelCaseTypes(model);
        //     }).trigger('change');
        // }

        $(document).on('change', '.price-sources-list .sku-all', (event) => {
            $('.price-sources-list .include-in-calc').prop('checked', false);

            if ($(event.currentTarget).is(':checked')) {
                $('.price-sources-list .include-in-calc').prop('checked', true);
            }

            this.insertCheckboxSkuArray();
        });
        
        $(document).on('change', '.sku-list .sku-all', (event) => {
            $('.sku').prop('checked', false);

            if ($(event.currentTarget).is(':checked')) {
                $('.sku').prop('checked', true);
            }

            this.insertCheckboxSkuArray();
        });

        $(document).on('change', '.modal-table .sku-all', (event) => {
            $('.modal-table .include-in-calc').prop('checked', false);

            if ($(event.currentTarget).is(':checked')) {
                $('.modal-table .include-in-calc').prop('checked', true);
            }

            this.insertCheckboxSkuArray();
        });

        $(document).on('change', '.sku-exclude-serials-all', (event) => {
            $('.sku-exclude-serials').prop('checked', false);

            if ($(event.currentTarget).is(':checked')) {
                $('.sku-exclude-serials').prop('checked', true);
            }

            this.insertCheckboxSkuArray();
        });

        let first = null;

        $(document).on('click', '.sku', (event) => {
            let $sku = $('.sku');

            if (!first || $('.sku:checked').length === 1) {
                first = $(event.currentTarget);
            }

            if (event.shiftKey) {
                let start = $sku.index(first);
                let end = $sku.index($(event.currentTarget));

                if (end < start) {
                    start = $sku.index($(event.currentTarget));
                    end = $sku.index(first);
                }

                $sku.slice(
                    Math.min(start, end),
                    Math.max(start, end) + 1
                ).prop('checked', first.is(':checked'));
            }

            this.insertCheckboxSkuArray();
        });

        let $actionWrapper = $('.action-wrapper');
        let $actionWrapperButtons = $actionWrapper.find('.action');

        $actionWrapperButtons.on('click', (event) => {
            event.preventDefault();

            let action = $(event.currentTarget).attr('href');
            let $multipleActionForm = $('.multiple-action');

            $multipleActionForm.attr('action', action).submit();
        });

        let $skuList = $('.sku-list');

        if ($skuList.length > 0) {
            this.createListTable($skuList);
        }

        let $skuListKadlubki = $('.sku-list-kadlubki');

        if ($skuListKadlubki.length > 0) {
            this.createKadlubekListTable($skuListKadlubki);
        }

        let $skuSoldHulksList = $('.sku-list-sold-hulks');

        if ($skuSoldHulksList.length > 0) {
            this.createHulksSoldListTable($skuSoldHulksList);
        }

        $('#sku_prices_form').submit(function (e) {
            e.preventDefault();
            if (!$('.price-sources-list .include-in-calc:checked').length) {
                let NotificationObject = new Notification();
                NotificationObject.setErrorAlert('Brak zaznaczonych wierszy', true);
                return;
            }

            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($(event.target));

            $.ajax({
                type: 'POST',
                url: Routing.generate('sku_price_sources_submit'),
                contentType: false,
                processData: false,
                data: new FormData(e.target),
                success: function (response) {
                    // window.location.href = Routing.generate('sku_prices_output', {
                    // })
                    // response = JSON.parse(response);
                    // let ret = that.print_r(response.results);
                    // let modalobj = new Modal();
                    // modalobj.createCustomModal('', ret, '');

                    let modalobj = new Modal();
                    modalobj.createCustomModal('',  response, '');

                    that.initModalEvents();
                    // document.querySelector('html').innerHTML = `<pre>` + ret + `</pre>`;
                    LoaderObject.removeLoader(loaderSpinner);
                    return;
                    // function showNotification(element) {
                    //     const NotificationObject = new Notification();
                    //     if (element.success) {
                    //         NotificationObject.setSuccessAlert(element.success, true);
                    //     } else if (element.info) {
                    //         NotificationObject.setInfoAlert(element.info, true);
                    //     } else if (element.error) {
                    //         NotificationObject.setErrorAlert(element.error, true);
                    //     }
                    // }
                    // response.forEach(showNotification);
                    
                },
                error: function (xhr, status, error) {
                    LoaderObject.removeLoader(loaderSpinner);

                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(error, true);
                }
            });
            return
        });

        $('a.sales_price_form_row_update').click(function (e) {
            e.preventDefault();
            let $row = $(e.target).parents('tr');
            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($row);

            let iaiCode = $row.find('input[type="hidden"').val();
            let priceRetail = $row.find('.row-price-retail').val();
            let priceStrikethrough = $row.find('.row-price-strikethrough').val();
            let percentThreshold = $row.find('.percent-threshold').val();
            let excludeSkusWithSerialsInCalculation = $row.find('.sku-exclude-serials').is(':checked');


            $.ajax({
                type: 'POST',
                url: Routing.generate('sku_price_sources_submit_row'),
                // contentType: false,
                // processData: false,
                data: { 'iaiCode': iaiCode,
                        'priceRetail': priceRetail,
                        'priceStrikethrough': priceStrikethrough,
                        'excludeSkusWithSerialsInCalculation': excludeSkusWithSerialsInCalculation,
                        'percentThreshold':  percentThreshold
                    },
                success: function (response) {
                    // window.location.href = Routing.generate('sku_prices_output', {
                    // })
                    // response = JSON.parse(response);
                    // let ret = that.print_r(response.results);
                                let modalobj = new Modal();
            modalobj.createCustomModal('',  response, '');
            that.initModalEvents();
            // modalobj.createCustomModal('', `<pre>` + ret + `</pre>`, '');

                    // document.querySelector('.rnew-modal-content').innerHTML = `<pre>` + ret + `</pre>`;
                    LoaderObject.removeLoader(loaderSpinner);
                    return;
                    // function showNotification(element) {
                    //     const NotificationObject = new Notification();
                    //     if (element.success) {
                    //         NotificationObject.setSuccessAlert(element.success, true);
                    //     } else if (element.info) {
                    //         NotificationObject.setInfoAlert(element.info, true);
                    //     } else if (element.error) {
                    //         NotificationObject.setErrorAlert(element.error, true);
                    //     }
                    // }
                    // response.forEach(showNotification);
                    
                },
                error: function (xhr, status, error) {
                    LoaderObject.removeLoader(loaderSpinner);

                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseText, true);
                }
            });
            return
        });

        $(".row-price-retail").each(function () {
            $(this).on('keyup change', (event) => {
                event.preventDefault();

                let $rowForm = $(event.target.parentElement.parentElement);
                $('.include-in-calc', $rowForm).prop( "checked", true );
            });
        });

        $(".percent-threshold").each(function () {
            $(this).on('keyup change', (event) => {
                event.preventDefault();

                let $rowForm = $(event.target.parentElement.parentElement.parentElement);
                $('.include-in-calc', $rowForm).prop( "checked", true );
            });
        });
        let $skuCreateForm = $('.form.sku.update-form');

        let $modelSelect = $skuCreateForm.find('#update_form_model');
        
        $modelSelect.on('change', (event) => {
            event.preventDefault();

            let option = $(event.currentTarget).find("option:selected");
            if ($(option).hasClass('not-completed')) {
                $('.badge-not-completed').show();
                $skuCreateForm.find('#update_form_submit').hide();
            } else {
                $('.badge-not-completed').hide();
                $skuCreateForm.find('#update_form_submit').show();    
            }
        }).trigger('change');
    }

    insertCheckboxSkuArray() {
        let $skuSet = $('.sku-set');
        let $skuCheckbox = $('.sku');
        let $actionWrapper = $('.action-wrapper');

        $skuSet.val('');

        let skuArray = [];

        $actionWrapper.hide();

        $skuCheckbox.each(function ($item) {
            if ($(this).is(':checked')) {
                skuArray.push($(this).val());
                $actionWrapper.show();
            }
        });

        $skuSet.val(skuArray.join(','));
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}