export default class Permissions {

    handleEvent() {
        let $permissions = $('.permissions');

        $permissions
            .find('input[type="checkbox"]')
            .not('input[value$=_view], input[value$=_process]')
            .parent()
            .hide();

        let $checkboxes = $permissions.find('input[value$=_view], input[value$=_process]');

        $checkboxes.on('change', (event) => {
            $(event.currentTarget)
                .closest('fieldset')
                .find('input[type="checkbox"]')
                .not('input[value$=_view], input[value$=_process]')
                .parent()
                .hide();

            if ($(event.currentTarget).is(':checked')) {
                $(event.currentTarget)
                    .closest('fieldset')
                    .find('input[type="checkbox"]')
                    .parent()
                    .show();
            } else {
                $(event.currentTarget)
                    .closest('fieldset')
                    .find('input[type="checkbox"]')
                    .prop('checked', false);
            }
        }).change();

        return this;
    }

    init() {
        this.handleEvent();
    }
}