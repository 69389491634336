import Loader from "./Loader";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Notification from './Notification.js';
import Cookie from "./Cookie.js";
import Table from "./Table.js";

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class Basket {


    /**
     * @param $basketList
     * @returns {jQuery}
     */
    createListTable($basketList) {
        let basket = +$basketList.attr('data-basket');

        const TableObject = new Table();
        return TableObject.createTable($basketList, {
            'ajax': Routing.generate('basket_info', {
                'basket': basket
            })
        });
    }

    /**
     * @param data
     * @param $table
     */
    addProductToBasket(data, $table) {
        let LoaderObject = new Loader();

        let basketAddSound = $('.basket-add-sound');
        let basketAddSoundFail = $('.basket-add-sound-fail');

        let $productAddForm = $('.add-product-form');

        let loaderSpinner = LoaderObject.createLoader($productAddForm);

        let basket = +$table.attr('data-basket');

        $.when($.ajax({
            type: 'POST',
            url: Routing.generate('basket_add_products', {
                'basket': basket,
            }),
            data: data,
            processData: false,
            contentType: false,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);
                basketAddSound[0].play();

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                if ($table) {
                    $table.DataTable().ajax.reload();
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);
                basketAddSoundFail[0].play();

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }));
    }

    /**
     * @param href
     * @param $table
     * @param $icon
     */
    removeProductFromBasket(href, $table, $icon) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.basket-list'));

        $.when($.ajax({
            type: 'GET',
            url: href,
            success: (response) => {
                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                $icon.closest('tr').hide();
            },
            error: (xhr, status, error) => {
                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        })).done(() => {
            LoaderObject.removeLoader(loaderSpinner);
        })
    }

    /**
     *
     */
    handleEvent() {
        let CookieInstance = new Cookie();

        let $basketList = $('.basket-list');
        let $basketDataTable = null;

        if ($basketList.length > 0) {
            $basketDataTable = this.createListTable($basketList);
        }

        let $productAddForm = $('.add-product-form');
        let $multiAdd = $productAddForm.find('.multiadd');

        let $serialNumber = $productAddForm.find('.serial-number');
        let $serialNumbers = $productAddForm.find('.serial-numbers');

        let multiAddCookie = CookieInstance.getCookie('multiadd');

        if (multiAddCookie === 'true') {
            $multiAdd.prop('checked', true);
            $serialNumber.hide();

            $serialNumbers.show(0, function () {
                $(this).find('textarea').val('').focus();
                $serialNumber.find('input').val();
            })
        } else {
            $multiAdd.prop('checked', false);

            $serialNumbers.hide();

            $serialNumber.show(0, function () {
                $(this).find('input').val('').focus();
                $serialNumbers.find('textarea').val('');
            })
        }

        $multiAdd.on('change', (event) => {
            if ($(event.currentTarget).is(':checked')) {
                $serialNumber.hide();

                $serialNumbers.show(0, function () {
                    $(this).find('textarea').val('').focus();
                    $serialNumber.find('input').val();
                    CookieInstance.setCookie('multiadd', true, 3600);
                })
            } else {
                $serialNumbers.hide();

                $serialNumber.show(0, function () {
                    $(this).find('input').val('').focus();
                    $serialNumbers.find('textarea').val('');
                    CookieInstance.setCookie('multiadd', false, 3600);
                })
            }
        });

        // $serialNumber.find('input').on('keyup', (event) => {
        //     event.preventDefault();
        //     event.stopPropagation();
        //
        //     let keyCode = event.keyCode || event.which;
        //
        //     if (keyCode === 13) {
        //         $productAddForm.trigger('submit');
        //
        //         $(event.currentTarget).val('');
        //     }
        // });

        $productAddForm.on('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();

            let formData = new FormData();
            formData.append('serialNumber', $serialNumber.find('input').val());
            formData.append('serialNumbers', $serialNumbers.find('textarea').val());

            $serialNumber.find('input').val('');
            $serialNumbers.find('textarea').val('');

            this.addProductToBasket(formData, $basketList)
        });

        $basketList.on('click', '.delete', (event) => {
            event.preventDefault();

            let href = $(event.currentTarget).attr('href');

            this.removeProductFromBasket(href, $basketList, $(event.currentTarget));
        });

        let $changeDelivery = $('.change-delivery');

        $changeDelivery.on('click', (event) => {
            event.preventDefault();

            let $changeDeliveryWrapper = $(event.currentTarget).siblings('.change-delivery-wrapper');

            if (!$changeDeliveryWrapper.is(':visible')) {
                $changeDeliveryWrapper.show(200);
            } else {
                $changeDeliveryWrapper.hide(200);
            }
        });
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}