const duration = 200;

export default class ProfileSlider {

    /**
     * @returns {number}
     */
    get duration() {
        return duration;
    }

    /**
     *
     */
    hide($profileSlider) {
        $profileSlider.slideUp(this.duration);
    }

    /**
     *
     */
    show($profileSlider) {
        $profileSlider.slideDown(this.duration);
    }

    /**
     *
     */
    handleEvent() {
        const $profileSliderTrigger = $('.rnew-profile-menu-slider-trigger');

        $profileSliderTrigger.on('click', (event) => {
            event.preventDefault();

            let $profileSlider = $('.rnew-profile-slider');

            this.hide($profileSlider);

            if (!$profileSlider.is(':visible')) {
                this.show($profileSlider);
            }
        });
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}