export default class DropzoneHelper {

    /**
     * @returns {boolean}
     */
    isSupport() {
        if (
            !'draggable' in document.createElement('span') ||
            typeof (FileReader) == 'undefined' ||
            !'draggable' in document.createElement('span') ||
            !'ondragstart' in document.createElement('span') ||
            !'ondrop' in document.createElement('span')
        ) {
            console.error('[RNEW v.0 API] Drag and drop not supported. Use traditional upload feature');

            return false;
        }

        console.info('[RNEW v.0 API] Drag and drop supported');

        return true;
    }

    /**
     * @param $dropzone
     * @returns {DropzoneHelper}
     */
    handleEvents($dropzone) {
        console.info('[RNEW v.0 API] Initializing drag end drop events');


        $dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', (event) => {
            event.preventDefault();

        }).on('drag dragenter', (event) => {
            console.info('[RNEW v.0 API] Drag start/enter/drag event');
        })
            .on('dragleave dragend drop', (event) => {
                console.info('[RNEW v.0 API] Drag dragleave/dragend/drop/dragend event');
            })
            .on('drop', (event) => {
                let dt = event.dataTransfer || (event.originalEvent && event.originalEvent.dataTransfer);
                let files = event.target.files || (dt && dt.files);
                let readers = [];

                let $dropzoneFile = $('.dropzone-file');

                $dropzoneFile.prop('files', files).change();

                for (let i = 0; i < files.length; i++) {
                    readers[i] = new FileReader();
                    readers[i].readAsDataURL(files[i]);

                    let $photoWrapper = $(event.currentTarget).find('.photos-wrapper');

                    readers[i].onload = (e) => {
                        $photoWrapper.each(function () {
                            let $photo = $(this).find('.photo-image');

                            if ($photo.hasClass('no-image') && !$photo.hasClass('reserved')) {
                                let $photoImg = $('<img src="" class="photo-image img-fluid">');
                                $photoImg.attr('src', e.target.result);
                                $photo
                                    .html($photoImg)
                                    .addClass('reserved');
                                return false;
                            }
                        })
                    }
                }
            });

        return this;
    }

    init() {
        let $dropzone = $('.dropzone');

        if (this.isSupport()) {
            this.handleEvents($dropzone);
        }
    }
}