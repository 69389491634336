const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import Notification from './Notification.js';
import Loader from "./Loader";

Routing.setRoutingData(routes);

export default class Carts {

    /**
     * @returns {*}
     * @param $table
     */
    addProductsFromCartToBasket(basketNumber, $table) {
        let cart = +$table.attr('data-cart');
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($table);

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate(
                'warehouse_cart_add_to_basket',
                {cart: cart, basket: basketNumber}
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                LoaderObject.removeLoader(loaderSpinner);
                response.forEach(showNotification);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     *
     */
    handleEvent() {
        let $cartsList = $('.cart-shelf-content-table');

        let $cartAddButton = $('.carts-shelf-basket-add-btn');

        $cartAddButton.on('click', (event) => {
            event.preventDefault();
            let basketNumber = $(".choose-basket-select option:selected").val();

            this.addProductsFromCartToBasket(basketNumber, $cartsList);
        });
    }

    init() {
        this.handleEvent();
    }
}
