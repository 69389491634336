const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import Notification from './Notification.js';
import Loader from "./Loader";

Routing.setRoutingData(routes);

export default class SerialNumberEditor {

    /**
     * @returns {*}
     * @param form
     */
    getProductBySerialNumberAjax(form) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.serial-number-editor'));

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('serial_number_editor_search'),
            contentType: false,
            processData: false,
            data: new FormData(form),
            success: function (response) {
                LoaderObject.removeLoader(loaderSpinner);

                if (response.serialNumber !== 'undefined') {
                    window.location.href = Routing.generate('serial_number_editor_process_serial', {
                        'serialNumber': response.serialNumber
                    })
                } else {
                    let $searchInput = $(form).find('[data-search-serial-number]');

                    $searchInput.val('');
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                }
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                let $searchInput = $(form).find('[data-search-serial-number]');

                $searchInput.val('');
                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     *
     */
    handleEvent() {
        let $serialNumberEditor = $('.serial-number-editor');

        let $serialNumberEditorSearchForm = $serialNumberEditor.find('.search-form');

        if ($serialNumberEditorSearchForm.length > 0) {
            let $searchInput = $serialNumberEditorSearchForm.find('[data-search-serial-number]');

            $searchInput.focus();

            $serialNumberEditorSearchForm.on('submit', (event) => {

                event.preventDefault();

                this.getProductBySerialNumberAjax(event.target);
            });
        }

        $serialNumberEditor.on('keydown keypress', (event) => {
            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                event.preventDefault();

                return false;
            }
        });
    }

    init() {
        this.handleEvent();
    }
}
