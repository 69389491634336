// import Loader from "./Loader";
// import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
// import Notification from "./Notification";
// import Table from './Table';
import interact from 'interactjs'
// const routes = require('../../../public/js/fos_js_routes.json');

// Routing.setRoutingData(routes);

export default class Batteries {
animateAppendTo = function(ele, sel, speed) {
  var $this = ele,
      newEle = $this.clone(true).appendTo(sel),
      newPos = newEle.position();
      newEle.hide();
  let originalPosition = $this.position();
  $this.css({ 
    position: "absolute",
    top: originalPosition.top + $this.height(), left: originalPosition.left
})

  $this.animate(newPos, speed, function() {
    // alert('move');
      // newEle.show();
      $this.remove();
      newEle.removeAttr("style");
      newEle.removeAttr("data-x");
      newEle.removeAttr("data-y");

      let greenColorPercentage = parseInt(newEle.data('percentage'));
      let redColorPercentage = 100 - greenColorPercentage;
      newEle.css("background", 'linear-gradient(to right, #4e4 ' + greenColorPercentage + '%, red ' + greenColorPercentage + '%, red ' + redColorPercentage + '100%)');

  });
  return newEle;
};

moveToElementPosition($elementID,$targetElementID){
  var pos = $targetElementID.position();
  var height = $targetElementID.outerHeight();
  var width = $targetElementID.outerWidth();
  
  $elementID.css("left", pos.left);
  $elementID.css("top", pos.top);
  $elementID.css("height", height);
  $elementID.css("width", width);
}
  dragMoveListener (event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  
    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
  
    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }
  
  initProductDropzone(productArea) {
    let that = this;
      let batteriesDropzone = productArea.find(".battery-dropzone");

      batteriesDropzone.each(function (index) {
        
        interact(this).dropzone({
            // only accept elements matching this CSS selector
            accept: ".yes-drop",
            // Require a 75% element overlap for a drop to be possible
            overlap: 0.75,
      
            checker: function (
              dragEvent,         // related dragmove or dragend
              event,             // Touch, Pointer or Mouse Event
              dropped,           // bool default checker result
              dropzone,          // dropzone Interactable
              dropzoneElement,   // dropzone element
              draggable,         // draggable Interactable
              draggableElement   // draggable element
            ) {
              let $newBatteryDropzone = $(dropzoneElement);
              return dropped && $newBatteryDropzone.hasClass('drop-active');
            },
            // listen for drop related events:
      
            ondropactivate: function (event) {
              let $newBatteryDropzone = $(event.target);

              let $battery = $(event.relatedTarget);
              let $oldProductInput = $('#' + $battery.data('old-input-id'));
              let $oldBatteryDropzone = $oldProductInput.parent();
              
              // add active dropzone feedback
            //   if ($(event.target).find('div').length ===  0 ) {
              // let slotNumber = $oldBatteryDropzone.data('batterySlot');
              if ($oldBatteryDropzone.data('batterySlot') == $newBatteryDropzone.data('batterySlot')
              //  && $oldBatteryDropzone != $newBatteryDropzone
              ) {
                event.target.classList.add("drop-active");
              }
            //   }
            },
            ondragenter: function (event) {
                var draggableElement = event.relatedTarget;
                var dropzoneElement = event.target;
        
                  let dropRect         = interact.getElementRect(dropzoneElement),
                  dropCenter       = {
                    x: dropRect.left + dropRect.width  / 2,
                    y: dropRect.top  + dropRect.height / 2
                  };
      
              event.draggable.draggable({
                snap: {
                  targets: [dropCenter]
                }
              });
              
                 // feedback the possibility of a drop
                //   if ($(event.target).find('div').length ===  0 ) {
                    dropzoneElement.classList.add("drop-target");
                    draggableElement.classList.add("can-drop");
                //   }
                //   draggableElement.textContent = "Dragged in";
            },
            ondragleave: function (event) {
                var draggableElement = event.relatedTarget;
                var dropzoneElement = event.target;
        
                  let $draggableElement = $(draggableElement);
                  let $dropzoneElement = $(dropzoneElement);
  
  
                  $draggableElement.data('old-input-id', $dropzoneElement.find("input.product-hidden-input").attr('id'));

                  
              // remove the drop feedback style
              event.target.classList.remove("drop-target");
              event.relatedTarget.classList.remove("can-drop");
            //   event.relatedTarget.textContent = "Dragged out";
            },
            ondrop: function (event) {
                
            //   event.relatedTarget.textContent = "Dropped";
                let $battery = $(event.relatedTarget);
                let $newBatteryDropzone = $(event.target);
                let $otherBattery = $newBatteryDropzone.find('div.battery');
                let $oldProductInput = $('#' + $battery.data('old-input-id'));
                let $oldBatteryDropzone = $oldProductInput.parent();
                let $oldBatterySlotInput = $oldBatteryDropzone.find("input.battery-slot-hidden-input");

                if ($newBatteryDropzone.find('input').length > 0 ){
                    let $newProductInput = $newBatteryDropzone.find("input.product-hidden-input");
                    let $newBatterySlotInput = $newBatteryDropzone.find("input.battery-slot-hidden-input");

                    let newProductInputValue = $newProductInput.val();
                    let oldProductInputValue = $oldProductInput.val();
                    let newBatterySlotInputValue = $newBatterySlotInput.val();
                    let oldBatterySlotInputValue = $oldBatterySlotInput.val();
                    
                    $battery.appendTo($newBatteryDropzone);
                    $battery.removeAttr("style");
                    $battery.removeAttr("data-x");
                    $battery.removeAttr("data-y");

                    if ($otherBattery.length > 0 && $otherBattery.data('batteryId') != $battery.data('batteryId')) {
                      $otherBattery = that.animateAppendTo($otherBattery,$oldBatteryDropzone, 700);
                      $otherBattery.data('old-input-id', $oldProductInput.attr('id'));
                    }

                    let greenColorPercentage = parseInt($battery.data('percentage'));
                    let redColorPercentage = 100 - greenColorPercentage;
                    $battery.css("background", 'linear-gradient(to right, #4e4 ' + greenColorPercentage + '%, red ' + greenColorPercentage + '%, red ' + redColorPercentage + '100%)');
                    $battery.data('old-input-id', $newProductInput.attr('id'))

                    $newProductInput.val(oldProductInputValue); 
                    $oldProductInput.val(newProductInputValue);

                    $newBatterySlotInput.val(oldBatterySlotInputValue); 
                    $oldBatterySlotInput.val(newBatterySlotInputValue);
        
                }   
            },
            ondropdeactivate: function (event) {
              // remove active dropzone feedback

              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
            },
          });
      
       });


  }

  initBatteryDragNDropEvents() {
        let that = this;
    var startPos = null;
  

    $('.battery').each(function (index) {
      let greenColorPercentage = parseInt($(this).data('percentage'));
      let redColorPercentage = 100 - greenColorPercentage;
      $(this).css("background", 'linear-gradient(to right, #4e4 ' + greenColorPercentage + '%, red ' + greenColorPercentage + '%, red ' + redColorPercentage + '100%)');
      $(this).data('old-input-id', $(this).parent().find("input.product-hidden-input").attr('id'));
    });
    
    // let interactionBattery = interact(".battery");
    

    // interactionBattery
    interact(".battery")
    // .draggable({
    //     inertia: true,
    //     // modifiers: [
    //     //   interact.modifiers.restrictRect({
    //     //     restriction: "parent",
    //     //     endOnly: true,
    //     //   }),
    //     // ],
    //     autoScroll: true,
    //     // dragMoveListener from the dragging demo above
    //     listeners: { move: this.dragMoveListener },
    //   })

      .draggable({
        snap: {
          targets: [startPos],
          range: Infinity,
          relativePoints: [ { x: 0.5, y: 0.5 } ],
          endOnly: true
        },
        onstart: function (event) {
          // debugger;
          var rect = interact.getElementRect(event.target);

          // let slotNumber = $(event.target).parent().data('batterySlot');
          // that.initBatterySlotDropzone($(".dropzone-product-2"), slotNumber);
  
            // record center point when starting the very first a drag
            startPos = {
              x: rect.left + rect.width  / 2,
              y: rect.top  + rect.height / 2
            }
  
          event.interactable.draggable({
            snap: {
              targets: [startPos]
            }
          });
        },
        // call this function on every dragmove event
        onmove: function (event) {
          var target = event.target,
              // keep the dragged position in the data-x/data-y attributes
              x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
              y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
  
          // translate the element
          target.style.webkitTransform =
          target.style.transform =
            'translate(' + x + 'px, ' + y + 'px)';
  
          // update the posiion attributes
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
          target.classList.add('getting--dragged');
        },
        onend: function (event) {
          // let slotNumber = $(event.target).parent().data('batterySlot');
          // that.removeBatterySlotDropzone($(".dropzone-product-2"), slotNumber);
          event.target.classList.remove('getting--dragged')
        }
      });
  
    //   .draggable({
    //     listeners: { move: this.dragMoveListener },
    //     inertia: true,
    //     modifiers: [
    //       interact.modifiers.restrictRect({
    //         restriction: 'parent',
    //         endOnly: true
    //       })
    //     ]
    //   })
    //   ;
  }

  handleEvent() {
    this.initProductDropzone($(".dropzone-product-1"));
    this.initProductDropzone($(".dropzone-product-2"));
    // this.initBatteriesProduct2();
    this.initBatteryDragNDropEvents();
  }

  /**
   *
   */
  init() {
    this.handleEvent();
  }
}
